@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@font-face {
    font-family: 'tekolight';
    src: url('../fonts/teko-light-webfont.woff2') format('woff2'),
    url('../fonts/teko-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tekomedium';
    src: url('../fonts/teko-medium-webfont.woff2') format('woff2'),
    url('../fonts/teko-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tekoregular';
    src: url('../fonts/teko-regular-webfont.woff2') format('woff2'),
    url('../fonts/teko-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tekosemibold';
    src: url('../fonts/teko-semibold-webfont.woff2') format('woff2'),
    url('../fonts/teko-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tekoregular';
    src: url('../fonts/teko-regular-webfont.woff2') format('woff2'),
    url('../fonts/teko-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tekobold';
    src: url('../fonts/teko-bold-webfont.woff2') format('woff2'),
    url('../fonts/teko-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.eot?73075143');
    src: url('../fonts/fontello.eot?73075143#iefix') format('embedded-opentype'),
    url('../fonts/fontello.woff2?73075143') format('woff2'),
    url('../fonts/fontello.woff?73075143') format('woff'),
    url('../fonts/fontello.ttf?73075143') format('truetype'),
    url('../fonts/fontello.svg?73075143#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-']:before, [class*=' icon-']:before {
    font-family: 'fontello', serif;
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: '\e800';
}

.icon-ok:before {
    content: '\e801';
}

.icon-cancel:before {
    content: '\e802';
}

.icon-plus:before {
    content: '\e803';
}

.icon-attention:before {
    content: '\e804';
}

.icon-info:before {
    content: '\e805';
}

.icon-question:before {
    content: '\e806';
}

.icon-globe:before {
    content: '\e807';
}

.icon-user:before {
    content: '\e808';
}

.icon-group:before {
    content: '\e809';
}

.icon-star:before {
    content: '\e80a';
}

.icon-share:before {
    content: '\e80b';
}

.icon-th-list:before {
    content: '\e80c';
}

.icon-link:before {
    content: '\e80d';
}

.icon-cog:before {
    content: '\e80e';
}

.icon-target:before {
    content: '\e80f';
}

.icon-globe-1:before {
    content: '\e810';
}

.icon-off:before {
    content: '\e811';
}

.icon-docs:before {
    content: '\e812';
}

.icon-flag-filled:before {
    content: '\e813';
}

.icon-pencil:before {
    content: '\e814';
}

.icon-target-1:before {
    content: '\e815';
}

.icon-cloud-sun-inv:before {
    content: '\e816';
}

.icon-calendar:before {
    content: '\e817';
}

.icon-globe-inv:before {
    content: '\f019';
}

.icon-food:before {
    content: '\f0f5';
}

.icon-bullseye:before {
    content: '\f140';
}

.icon-wheelchair:before {
    content: '\f193';
}

.icon-trash:before {
    content: '\f1f8';
}

.icon-clone:before {
    content: '\f24d';
}

.icon-chart-bar:before {
    content: '\e818';
}

.icon-shop:before {
    content: '\e819';
}

.icon-th-large:before {
    content: '\e81a';
}

.icon-list-numbered:before {
    content: '\e81b';
}

.icon-money:before {
    content: '\f0d6';
}

.icon-videocam:before {
    content: '\e81c';
}

.icon-mobile:before {
    content: '\e81d';
}

.icon-mail:before {
    content: '\e81e';
}

body {
    @apply bg-theme-dark text-theme-light;
    background: url('../images/tile.png') repeat left top fixed;
    font-family: 'Poppins', sans-serif;
    font-size: 85%;
}

a {
    @apply text-theme-green hover:text-theme-dimmed_light;
}

html.admin body {
    @apply bg-theme-medium_dark_focus bg-none !important;
}

.black-body body {
    @apply bg-theme-black bg-none;
}

.global-container {
    @apply relative;
}

.global-container::before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 13px;
    display: block;
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(47, 102, 113) 59%);
    left: 0;
    top: 60px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100% - 70px) 100%, calc(100% - 50px) 5px, 50px 5px, 70px 100%, 0 100%);
    z-index: 10;
}

.main-container {
    @apply px-2 lg:px-4;
}

.main-column-limiter {
    @apply relative m-auto;
    max-width: 2040px;
}

.rows-fieldset {
    @apply mt-3 mb-5 p-2 lg:mt-5 lg:mb-6 lg:px-4 lg:px-3 rounded-sm relative overflow-hidden;
    border-top: 1px solid #6B6C79;
    background: linear-gradient(18deg, rgb(22, 39, 59) 0%, rgb(4, 21, 41) 6%, rgb(27, 44, 64) 44%, rgb(23, 40, 60) 100%);
    border-radius: 2px;
    box-shadow: 0 0 8px 4px #52536073;
    border-left: 1px solid #6B6C79;
    border-bottom: 1px solid #535461;
    border-right: 1px solid #535461;
}

.rows-fieldset::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    opacity: 0;
    transform: skewX(135deg);
    background: rgba(0, 168, 225, 0.13);
    background: linear-gradient(to right, rgba(0, 168, 225, 0) 0%, rgba(0, 168, 225, 0.13) 77%, rgba(0, 168, 225, 0.5) 92%, rgba(0, 168, 225, 0.0) 100%);
    animation-name: scan;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-delay: 0.4s;
}

h1, h2, h3, h4, h5 {
    font-family: 'tekobold', sans-serif;
    @apply uppercase;
}

h2 {
    @apply text-4xl py-2 lg:py-4;
}

h3 {
    @apply text-3xl py-2 lg:py-3;
}

h4 {
    @apply text-2xl py-1 lg:py-2;
}

h5 {
    @apply text-xl py-1;
}

@keyframes scan {
    from {
        opacity: 0;
        top: -10%;
    }
    50% {
        opacity: 0.2;
        top: 100%
    }
    to {
        opacity: 0;
        top: -10%;
    }
}

.actions-fieldset {
    @apply mt-3 mb-5 p-2 lg:mt-5 lg:mb-5 lg:p-4 bg-transparent flex justify-center flex-row items-center border-0 shadow-none !important;
    background: none !important;
}

fieldset legend {
    @apply px-2 lg:px-6 py-1 lg:py-2 bg-theme-medium_dark_focus border-theme-medium_light border rounded font-bold;
    box-shadow: 0 2px 4px #5E5F6C7F;
}

fieldset legend span {
    @apply mr-2;
}

label {
    @apply font-bold;
}

label.required::after {
    content: '*';
    @apply relative inline-block ml-1 text-theme-danger_light font-bold;
}

.form-widget-container {
    @apply my-1 lg:my-2;
}

.form-widget-container img {
    @apply my-1 border border-theme-light;
}

.form-widget-container .help-text {
    @apply text-theme-dimmed_light text-xs;
}

.form-info-container {
    @apply py-1 pl-8 relative;
}

.form-info-container::before {
    content: '▸';
    position: absolute;
    left: 0.5em;
}

blockquote {
    @apply italic border-l border-theme-dimmed_light border-solid bg-theme-medium_dark_focus text-theme p-2 lg:p-4 my-3 rounded-br-xl;
}

table {
    @apply my-3 border border-solid border-theme-medium_dark_focus max-w-full table-fixed w-full;
}

tr {
    @apply border border-solid border-theme-medium_dark_focus;
}

td, th {
    @apply px-3 py-1 border border-solid border-theme-medium_dark_focus break-words;
}

th {
    @apply bg-theme-medium_dark_hover hover:bg-theme-medium_dark_focus;
}

tr:nth-child(even),
.table-items .row:nth-child(even) {
    @apply bg-theme-medium_dark hover:bg-theme-medium_dark_focus;
}

tr:nth-child(odd),
.table-items .row:nth-child(odd) {
    @apply bg-theme-medium_dark_hover hover:bg-theme-medium_dark_focus;
}

hr {
    @apply my-3 border-theme-medium_light border-t border-solid;
}

ol {
    @apply list-decimal list-inside ml-7 my-2;
}

ul {
    @apply list-disc list-inside ml-7 my-2;
}

input, select {
    max-width: 1000px;
}

input {
    @apply bg-theme-medium_dark hover:bg-theme-medium_dark_hover focus:bg-theme-medium_dark_focus px-2 py-1 border-theme-dimmed_light border-2 focus:border-theme-light rounded;
    transition: all 0.1s ease-in;
    outline: none;
}

input[type='file'] {
    max-width: 452px;
}

input[type='date'] {
    @apply w-full;
}

select {
    @apply bg-theme-medium_dark hover:bg-theme-medium_dark_hover focus:bg-theme-medium_dark_focus px-2 py-1 border-theme-dimmed_light border-2 focus:border-theme-light rounded;
    background: linear-gradient(0deg, rgba(22, 39, 59, 1) 0%, rgba(101, 111, 125, 1) 48%, rgba(64, 80, 101, 1) 52%, rgba(38, 55, 75, 1) 100%);
    outline: none;
}

select:hover {
    background: linear-gradient(0deg, rgba(32, 49, 69, 1) 0%, rgba(111, 121, 135, 1) 48%, rgba(74, 90, 111, 1) 52%, rgba(48, 65, 85, 1) 100%);
}

select option,
select optgroup {
    @apply bg-theme-medium_dark hover:bg-theme-medium_dark_hover p-1;
}

.datetime-widget select,
.date-widget select,
.time-widget select {
    @apply inline-block w-20;
}

.datetime-widget {
    @apply flex;
    max-width: 1000px;
}

.datetime-widget .date-widget,
.datetime-widget .time-widget {
    @apply lg:mr-6;
}

.date-widget select {
    @apply mr-1;
}

.time-widget select {
    @apply mx-1;
}

input::placeholder {
    @apply text-theme-medium_dark_focus;
}

/*noinspection CssOverwrittenProperties*/
.btn {
    @apply relative bg-theme-medium_dark_focus hover:bg-theme-dimmed_light focus:bg-theme-gray px-8 lg:px-12 py-1 py-2 rounded-sm border-theme-light border-2 hover:border-theme-light focus:border-theme-yellow font-bold mx-2 lg:mx-4;
    @apply text-theme hover:text-theme-green !important;
    border-image-outset: 0;
    border-image-repeat: round;
    border-image-slice: 16%;
    border-image-source: url('../images/btn-bg.png');
    border-image-width: 30px;
    transition: all 0.15s ease-in;
    border-width: 1px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.8);
}

.btn:hover {
    box-shadow: 0 0 5px -1px rgba(255, 255, 255, 0.5);
    text-shadow: 0 0 2px rgba(32, 49, 69, 0.5);
}

.btn-admin {
    @apply bg-theme-danger_light hover:bg-theme-danger_focus;
}

.btn-delete {
    @apply bg-theme-danger hover:bg-theme-danger_hover focus:bg-theme-danger_focus;
}

.btn-ok {
    @apply bg-theme-ok hover:bg-theme-ok_hover focus:bg-theme-ok_focus;
}

.btn-active {
    @apply opacity-40 bg-theme-medium_dark hover:bg-theme-medium_dark_hover text-theme-medium_light;
}

.btn-ok::before {
    @apply relative;
    content: '\e801';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-add::before {
    @apply relative;
    content: '\e803';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-delete::before {
    @apply relative;
    content: '\e802';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-edit::before {
    @apply relative;
    content: '\e814';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-list::before {
    @apply relative;
    content: '\e80c';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-clone::before {
    @apply relative;
    content: '\f24d';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-zone::before {
    @apply relative;
    content: '\f019';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-user::before {
    @apply relative;
    content: '\e808';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-format::before {
    @apply relative;
    content: '\f24d';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-info::before {
    @apply relative;
    content: '\e805';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-target::before {
    @apply relative;
    content: '\e815';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

.btn-search::before {
    @apply relative;
    content: '\e800';
    font-family: 'fontello', sans-serif;
    top: 0;
    left: -0.5em;
    line-height: 1em;
    font-size: 1em;
}

main {
    padding-top: 80px;
}

main, footer {
    @apply relative block;
}

.section-separator {
    @apply w-full bg-theme-medium_dark_hover h-6 lg:h-12 relative;
}

.transparent-section-separator {
    @apply bg-transparent !important;
}

.white-separator {
    @apply bg-theme !important;
}

.first-section-separator {
    background: none;
    z-index: 0;
    box-shadow: 0 40px 50px 0 rgba(255, 255, 255, 0.2);
    height: 16px;
}

.first-section-separator::before {
    content: '';
    left: 0;
    top: 60px;
    @apply bg-theme-medium_dark_hover w-1/2 h-full absolute;
    clip-path: polygon(0 0, 100% 0, 0 100%);
}

.first-section-separator::after {
    content: '';
    right: 0;
    top: 60px;
    @apply bg-theme-medium_dark_hover w-1/2 h-full absolute;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.last-section-separator {
    background: none;
    height: 16px;
}

.last-section-separator::before {
    content: '';
    left: 0;
    bottom: 0;
    @apply bg-theme-medium_dark_hover w-1/2 h-full absolute;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
}

.last-section-separator::after {
    content: '';
    right: 0;
    bottom: 0;
    @apply bg-theme-medium_dark_hover w-1/2 h-full absolute;
    clip-path: polygon(0 100%, 100% 0, 100% 100%);
}

main h1 {
    @apply text-3xl lg:text-5xl text-center uppercase lg:py-4 px-2 mt-2;
    font-family: 'tekobold', sans-serif;
    line-height: 0.9em;
}

main h1 span, main h2 span {
    @apply hidden lg:inline-block;
}

main h1 span.text-theme-yellow, main h2 span.text-theme-yellow {
    @apply inline-block;
}

main h2 {
    @apply text-lg lg:text-3xl text-center uppercase px-2 lg:py-3;
    font-family: 'tekoregular', sans-serif;
}

main h3 {
    @apply text-lg lg:text-xl text-center uppercase px-2;
    font-family: 'tekoregular', sans-serif;
}

main h4 {
    @apply text-base lg:text-lg text-center uppercase lg:py-1 px-2 cursor-help mt-2;
    font-family: 'tekolight', sans-serif;
    margin-top: -1em !important;
}

@media only screen and (max-width: 1024px) {
    main h4 {
        margin-top: 0 !important;
    }
}


header {
    @apply fixed w-full left-0 top-0 shadow-lg border-b;
    background: linear-gradient(2deg, rgba(5, 19, 36, 1) 0%, rgba(24, 40, 64, 1) 40%, rgba(10, 26, 48, 1) 68%, rgba(38, 55, 75, 1) 100%);
    height: 60px;
    z-index: 5000;
}

html.admin header {
    background: linear-gradient(2deg, rgba(36, 19, 5, 1) 0%, rgba(64, 40, 24, 1) 40%, rgba(48, 26, 10, 1) 68%, rgba(75, 55, 38, 1) 100%);
}

header::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    opacity: 0;
    transform: skewX(135deg);
    background: rgba(0, 168, 225, 0.13);
    background: linear-gradient(to right, rgba(0, 168, 225, 0.13) 0%, rgba(0, 168, 225, 0.13) 77%, rgba(0, 168, 225, 0.5) 92%, rgba(0, 168, 225, 0.0) 100%);
    animation-name: shine;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
}

html.admin header::after {
    background: linear-gradient(to right, rgba(225, 168, 0, 0.13) 0%, rgba(225, 168, 0, 0.13) 77%, rgba(225, 168, 0, 0.5) 92%, rgba(225, 168, 0, 0.0) 100%);
}

@keyframes shine {
    to {
        opacity: 1;
        left: 200%;
    }
}

header .menu-container {
    @apply w-full flex flex-col lg:flex-row flex-nowrap justify-around m-0 bg-theme-medium_dark lg:bg-transparent;
}

header .menu-container > li {
    @apply text-center relative;
    width: 15%;
    transition: all 0.15s ease-out;
    z-index: 5010;
}

header .menu-container > li:first-of-type {
    @apply overflow-hidden;
}

header .menu-container > li:first-of-type::before {
    opacity: 0;
    transition: opacity 0.1s ease-in;
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    from {
        opacity: 0.2;
    }
    15% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    70% {
        opacity: 0.3;
    }
    to {
        opacity: 0.2;
    }
}

header .menu-container > li:first-of-type:hover::before {
    @apply block absolute m-auto;
    box-shadow: 0 0 85px 35px #fff;
    content: '';
    width: 2%;
    height: 2%;
    top: 49%;
    left: 49%;
}

header .menu-container > li li {
    @apply text-center relative inline-block;
    width: 100%;
    transition: all 0.15s ease-out;
}

header .menu-container li a {
    @apply relative inline-block w-full h-full px-3 lg:px-4 py-0 uppercase text-lg lg:text-xl text-theme hover:text-theme-yellow;
    transition: all 0.2s ease;
    font-family: 'tekomedium', sans-serif;
    line-height: 59px;
    background: transparent;
}

header .menu-container li:hover a {
    text-shadow: 0 0 3px #303030;
}

header .menu-container li li:hover a {
    @apply bg-theme-medium_light;
    text-shadow: 0 0 3px #303030;
}

header .menu-container > li ul {
    background: linear-gradient(2deg, rgba(5, 19, 36, 1) 0%, rgba(24, 40, 64, 1) 40%, rgba(10, 26, 48, 1) 68%, rgba(38, 55, 75, 1) 100%);
    transition: all 0.1s ease-in-out 0.1s;
    z-index: 5000;
    @apply border-theme-dimmed_light m-0;
}

header .menu-container > li > ul {
    width: calc(100% - 32px);
    @apply border-l border-r border-b shadow-2xl relative lg:absolute text-theme-dark;
    top: -800px;
    left: 10px;
    opacity: 0;
}

header .menu-container > li > ul > li > ul {
    @apply relative lg:absolute lg:left-full lg:top-0 w-full border lg:hidden opacity-0;
}

header .menu-container > li > ul > li:hover > ul {
    @apply lg:block opacity-100;
}

html.admin .menu-container > li ul {
    background: linear-gradient(2deg, rgba(36, 19, 5, 1) 0%, rgba(64, 40, 24, 1) 40%, rgba(48, 26, 10, 1) 68%, rgba(75, 55, 38, 1) 100%);
}

header .menu-container > li:hover > ul {
    top: 60px;
    opacity: 1.0;
    @apply text-theme;
}

header .menu-container li li a {
    @apply text-base px-3 lg:px-4 py-1 lg:py-2;
}

header .menu-container li a .menu-link-before {
    @apply absolute inline-block w-full h-full top-0 left-0 right-0 bottom-0 bg-transparent;
    transition: all 0.1s ease;
    filter: drop-shadow(0px 0 5px #2f6671);
    opacity: 0;
}

header .menu-container li:hover a .menu-link-before {
    opacity: 1.0;
}

header .menu-container > li > a > .menu-link-before::before,
header .menu-container > li > a > .menu-link-before::after {
    clip-path: polygon(20px 0, 100% 0, calc(100% - 20px) 100%, 0 100%);
    position: absolute;
}

header .menu-container li a .menu-link-before::after {
    transition: all 0.2s ease;
    background: linear-gradient(0deg, rgba(5, 19, 36, 1) 0%, rgba(24, 40, 64, 1) 40%, rgba(10, 26, 48, 1) 68%, rgba(38, 55, 75, 1) 100%);
}

html.admin header .menu-container li a .menu-link-before::after {
    background: linear-gradient(0deg, rgba(56, 39, 25, 1) 0%, rgba(84, 60, 44, 1) 40%, rgba(68, 46, 30, 1) 68%, rgba(95, 75, 58, 1) 100%);
}

header .menu-container li:hover a .menu-link-before::before {
    background: linear-gradient(0deg, rgba(87, 58, 18, 1) 0%, rgba(116, 142, 57, 1) 44%, rgba(125, 208, 57, 1) 59%, rgba(114, 31, 45, 1) 100%);
    content: '';
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5010;
}

header .menu-container li:hover a .menu-link-before::after {
    background: linear-gradient(0deg, rgba(77, 8, 70, 1) 0%, rgba(99, 129, 148, 1) 44%, rgba(47, 102, 113, 1) 59%, rgba(2, 15, 32, 1) 100%);
    content: '';
    bottom: 0;
    top: 0;
    left: 8px;
    right: 2px;
    z-index: 5020;
}

header .menu-container li a .menu-link-container {
    position: relative;
    z-index: 5050;
}

header .menu-container > li {
    @apply inline-block w-full;
}

header .menu-container > li > ul > li {
    @apply inline-block;
}

header .nav-home-link {
    @apply p-0 text-center cursor-pointer flex justify-center !important;
}

header .front-events-main .menu-link-container::before {
    content: '\f140';
    font-family: 'fontello', sans-serif;
    position: absolute;
    left: -1.4em;
    top: 0;
    line-height: 1em;
    font-size: 0.5em;
}

header .tournaments-home .menu-link-container::before {
    content: '\e812';
    font-family: 'fontello', sans-serif;
    position: absolute;
    left: -1.4em;
    top: 0;
    line-height: 1em;
    font-size: 0.5em;
}

header .tournaments-zones .menu-link-container::before {
    content: '\f019';
    font-family: 'fontello', sans-serif;
    position: absolute;
    left: -1.4em;
    top: 0;
    line-height: 1em;
    font-size: 0.5em;
}

header .tournaments-formats .menu-link-container::before {
    content: '\e80c';
    font-family: 'fontello', sans-serif;
    position: absolute;
    left: -1.4em;
    top: 0;
    line-height: 1em;
    font-size: 0.5em;
}

header .tournaments-search .menu-link-container::before {
    content: '\e800';
    font-family: 'fontello', sans-serif;
    position: absolute;
    left: -1.4em;
    top: 0;
    line-height: 1em;
    font-size: 0.5em;
}

header .tournaments-organizers-info-page .menu-link-container::before {
    content: '\e805';
    font-family: 'fontello', sans-serif;
    position: absolute;
    left: -1.4em;
    top: 0;
    line-height: 1em;
    font-size: 0.5em;
}

header .users-map .menu-link-container::before {
    content: '\e809';
    font-family: 'fontello', sans-serif;
    position: absolute;
    left: -1.4em;
    top: 0;
    line-height: 1em;
    font-size: 0.5em;
}

header .app-logout .menu-link-container::before {
    content: '\e811';
    font-family: 'fontello', sans-serif;
    position: absolute;
    left: -1.4em;
    top: 0;
    line-height: 1em;
    font-size: 0.5em;
}

header .app-logout .menu-link-container {
    @apply text-theme-danger_light;
}

.main-menu-logo {
    @apply hidden lg:inline-block !important;
}

.main-menu-toggle {
    @apply w-full text-center inline-block lg:hidden !important;
}

.main-menu-container-primary {
    @apply opacity-0 lg:opacity-100 hidden lg:flex !important;
}

.main-menu-container-primary.menu-open {
    @apply opacity-100 block !important;
}

@media only screen and (max-width: 1024px) {
    header {
        @apply w-full h-auto fixed;
    }
}

@media only screen and (max-width: 1024px) {
    header .main-menu-container-primary {
        @apply block relative;
    }
}

@media only screen and (max-width: 1024px) {
    header .main-menu-container-primary > li > ul {
        @apply relative top-auto left-auto opacity-100;
    }
}

@media only screen and (max-width: 1024px) {
    header .main-menu-container-primary {
        @apply overflow-y-scroll relative;
        height: calc(100vh - 60px);
        width: 100vw;
        top: 0;
    }
}

@media only screen and (max-width: 1024px) {
    header .main-menu-container-primary > li:hover > ul {
        top: auto;
    }
}

@media only screen and (max-width: 1024px) {
    header .main-menu-container-primary > li > ul {
        @apply w-auto;
    }
}

@media only screen and (max-width: 1024px) {
    header .main-menu-container-primary > li > a {
        @apply bg-theme-dark text-theme-yellow leading-10 py-2;
    }
}

@media only screen and (max-width: 1024px) {
    header .main-menu-container-primary > li > a > .menu-link-before::before {
        clip-path: none;
        left: 4px !important;
        right: 4px !important;
    }
}

@media only screen and (max-width: 1024px) {
    header .main-menu-container-primary > li > a > .menu-link-before::after {
        clip-path: none;
        left: 2px !important;
        right: 2px !important;
    }
}

@media only screen and (max-width: 1024px) {
    header .main-menu-container-primary > li > ul {
        @apply border-none border-transparent border-0 !important;
    }
}

@media only screen and (max-width: 1024px) {
    header .main-menu-toggle {
        height: 55px !important;
    }
}

@media only screen and (max-width: 1024px) {
    #main-menu-toggle {
        @apply fixed w-full shadow-lg border-none relative;
        background: linear-gradient(2deg, rgba(5, 19, 36, 1) 0%, rgba(24, 40, 64, 1) 40%, rgba(10, 26, 48, 1) 68%, rgba(38, 55, 75, 1) 100%);
        height: 55px !important;
    }
}

@media only screen and (max-width: 1024px) {
    #main-menu-toggle::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 300px;
        height: 100%;
        opacity: 0;
        transform: skewX(135deg);
        background: rgba(0, 168, 225, 0.13);
        background: linear-gradient(to right, rgba(0, 168, 225, 0.13) 0%, rgba(0, 168, 225, 0.13) 77%, rgba(0, 168, 225, 0.5) 92%, rgba(0, 168, 225, 0.0) 100%);
        animation-name: shine;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-delay: 2s;
    }
}

@media only screen and (max-width: 1024px) {
    .tournament-size-badge-container {
        transform: scale(0.7);
    }
}

.nav-home-link img {
    height: 50px;
    margin-top: 2px;
}

.menu-container .active .menu-link-container {
    @apply underline !important;
}

footer {
    @apply border-t border-theme-medium_dark_focus relative block;
    background: linear-gradient(15deg, rgba(5, 19, 36, 1) 0%, rgba(24, 40, 64, 1) 40%, rgba(10, 26, 48, 1) 68%, rgba(38, 55, 75, 1) 100%);
    box-shadow: 0 -40px 50px 0 rgba(255, 255, 255, 0.2);
}

.circle-container {
    content: '';
    @apply absolute m-auto;
    width: 100%;
    height: 100%;
    background: url('../images/circular.png') center center no-repeat scroll transparent;
}

.bottom-transition-background-container {
    content: '';
    @apply relative left-0 bottom-0 w-full h-16;
    background: linear-gradient(180deg, rgba(6, 23, 43, 0) 0%, rgba(6, 23, 43, 1) 100%);
}

.footer-container {
    @apply p-1 lg:p-6;
}

.footer-container nav {
    @apply pt-2 lg:pt-5;
}

footer .menu-container {
    @apply flex flex-row flex-wrap justify-between p-2 m-2;
}

footer .menu-container > li {
    @apply w-full lg:w-1/2 xl:w-1/3 text-center list-none;
}

footer .menu-container > li a {
    @apply inline-block px-2 lg:px-5 py-1 hover:text-theme-yellow w-full rounded;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
    transition: all 0.2s ease-in;
}

footer .menu-container > li a:hover {
    background-color: rgba(41, 58, 78, 0.5);
}

.subfooter-container p {
    @apply text-theme-dimmed_light italic text-center p-1 lg:p-3 text-xs;
}

.subfooter-container p::before {
    @apply border-t border-theme-medium_dark_focus border-solid relative w-1/3 block left-1/3 mb-4;
    content: ' ';
}


.subfooter-container p img {
    @apply inline-block transition mb-4 !important;
}

.table-container {
    @apply flex flex-col mb-5;
}

.table-container .table-item {
    @apply bg-theme text-theme-medium_dark rounded mb-2 lg:mb-4 relative;
    box-shadow: 0 0 1px 3px #505050;
    padding: 1.4em 6px 1em 7px;
}

.table-container .table-item-stripe-container {
    @apply absolute;
    transform: skewx(-20deg);
    background: linear-gradient(90deg, rgba(5, 19, 36, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
    width: 150px;
    right: calc(5% + 150px);
    height: 100%;
    top: 0;
    z-index: 0;
}

.tournaments-info-container {
    @apply relative z-10;
}

/*noinspection CssOverwrittenProperties*/
.tournament-list-item {
    @apply my-10 lg:my-12 mx-4 lg:mx-8 text-theme-dark;
    background: url('../images/world.png') center center scroll transparent;
    border-image-outset: 25px;
    border-image-repeat: round;
    border-image-slice: 33.33333%;
    border-image-source: url('../images/bordertile.png');
    border-image-width: 130px;
    transition: all 0.3s ease-out;
    border-width: 1px;
}

.tournament-list-item a {
    @apply text-theme-dark hover:text-theme-medium_light;
}

@media only screen and (max-width: 1024px) {
    .tournament-list-item h2 a {
        @apply inline-block w-full !important;
    }

    .tournament-list-item h2 span {
        @apply inline-block w-full !important;
    }
}

.virtual-list-item {
    border-image-source: url('../images/bordertile-virtual.png');
    background: url('../images/circuits.png') center center scroll transparent;
}

.table-item.tournament-list-item:hover {
    box-shadow: 0 0 20px 12px #fefefe;
}

.virtual-list-item:hover {
    box-shadow: 0 0 20px 12px #fee0fe !important;
}

.tournament-list-item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    opacity: 0;
    transform: skewX(135deg);
    background: rgba(0, 168, 225, 0.13);
    background: linear-gradient(to right, rgba(0, 168, 225, 0.3) 0%, rgba(0, 168, 225, 0.5) 77%, rgba(10, 178, 235, 0.8) 92%, rgba(0, 168, 225, 0.4) 100%);
    animation-name: scan;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-delay: 0.4s;
}

.tournament-size-badge-container {
    @apply text-3xl text-center text-theme-badge;
    box-shadow: 0 0 8px 2px #000000;
    background: url('../images/badge.png') no-repeat center center scroll transparent;
    height: 70px;
    width: 70px;
    line-height: 70px;
    font-family: 'tekobold', sans-serif;
    border-radius: 50%;
    text-shadow: 0 0 5px #000;
    cursor: default;
    user-select: none;
}

.tournament-list-item .tournament-size-badge-container {
    @apply absolute;
    top: -36px;
    left: calc(50% - 36px);
}

.tournament-list-item:hover .tournament-size-badge-container {
    box-shadow: 0 0 8px 2px #404040;
}

.badge-size-xs {
    filter: saturate(0%);
}

.badge-size-s {
    filter: hue-rotate(100deg);
}

.badge-size-m {
    filter: hue-rotate(180deg);
}

.badge-size-l {
    filter: hue-rotate(270deg);
}

.badge-size-xl {
    filter: hue-rotate(300deg);
}

.badge-size-xxl {
    filter: brightness(110%) contrast(110%);
}

.tournaments-info-right > div {
    cursor: help;
    @apply w-full;
}

.tournaments-info-left {
    background: linear-gradient(90deg, rgba(220, 220, 236, 0) 0%, rgba(220, 220, 236, 0.6) 80%);
}

.tournaments-info-right {
    @apply w-full lg:w-1/2 flex flex-col justify-between lg:pl-2 lg:py-1 text-center xl:text-left leading-normal xl:leading-tight;
    background: linear-gradient(270deg, rgba(220, 220, 236, 0) 0%, rgba(220, 220, 236, 0.6) 80%);
}

@media only screen and (max-width: 1024px) {
    .tournaments-info-right {
        @apply bg-none !important;
    }
}

.table-item-actions {
    @apply w-full text-center mt-4 lg:mt-6 mb-1 lg:mb-2 relative z-10;
}

.navigation-container {
    @apply block w-full text-center;
}

.navigation-container span,
.navigation-container a {
    @apply inline-block text-center;
}

.navigation-container span.current,
.navigation-container a {
    @apply px-4 lg:px-6 py-1 lg:py-2 font-bold;
}

.navigation-container a {
    @apply w-full h-full hover:bg-theme-medium_dark_focus rounded;
}

.navigation-container span.current {
    @apply text-theme-dimmed_light;
}

.virtual {
    @apply text-theme-virtual_front;
}

.license {
    @apply text-theme-license_front;
}

.flash-message {
    @apply bg-theme-light mt-2 mb-1 px-4 py-1 rounded text-theme-dark shadow border border-theme-dimmed_light border-solid cursor-help;
}

.flash-message a {
    @apply text-theme-dark hover:text-theme-medium_dark_hover;
}

.flash-message:first-of-type {
    @apply mt-6;
}

.flash-message:last-of-type {
    @apply mt-6;
}

.flash-info,
.flash-notice,
.flash-warning,
.flash-error,
.flash-success {
    @apply pl-3;
}

.flash-info::before,
.flash-notice::before,
.flash-warning::before,
.flash-error::before,
.flash-success::before {
    font-family: 'fontello', serif;
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flash-info {
    @apply bg-theme-flash_info_back;
}

.flash-info::before {
    content: '\e80b';
}

.flash-notice {
    @apply bg-theme-flash_notice_back;
}

.flash-notice::before {
    content: '\e805';
}

.flash-warning {
    @apply bg-theme-flash_warning_back;
}

.flash-warning::before {
    content: '\e804';
}

.flash-error {
    @apply bg-theme-flash_error_back;
}

.flash-error::before {
    content: '\e802';
}

.flash-success {
    @apply bg-theme-flash_success_back;
}

.flash-success::before {
    content: '\e801';
}

.cke_top {
    @apply bg-theme-light !important;
}

a.cke_button {
    @apply border-transparent border border-solid hover:border-theme-medium_dark !important;
    padding: 4px 6px !important;
}

a.cke_button_on {
    @apply border-theme-medium_light border border-solid hover:border-theme-medium_dark !important;
}

textarea {
    @apply bg-theme-black text-theme;
}

textarea.cke_source {
    @apply bg-theme-medium_dark p-2 !important;
}

.form-errors {
    @apply bg-theme-flash_error_back text-theme-danger my-2;
}

.form-error {
    @apply px-2 px-1;
}

.tournament-banner-container {
    @apply relative w-full overflow-hidden border-l-4 border-r-4 border-t-4 border-theme border-solid shadow-2xl;
    height: 400px;
}

@media only screen and (max-width: 1024px) {
    .tournament-banner-container {
        height: auto;
        min-height: 200px;
    }
}

.tournament-banner-container-virtual {
    @apply border-theme-virtual_back;
}

.maps-banner-container {
    @apply relative w-full overflow-hidden shadow-2xl;
    height: 680px;
}

@media only screen and (max-width: 1024px) {
    .maps-banner-container {
        height: 500px;
    }

}

.tournament-banner-container img,
.tournament-header-no-banner {
    @apply absolute left-0 top-0 w-full h-full;
}

.page-header-title {
    @apply absolute flex w-full h-full left-0 top-0 flex-col justify-center;
}

.user-header-title {
    @apply flex w-full h-full flex-col justify-center;
}

.page-header-title h1,
.user-header-title h1 {
    @apply inline-block w-full text-center px-2 lg:px-6 py-4 lg:py-6;
    background-color: rgba(0, 0, 0, 0.5);
    height: fit-content;
}

.tournament-header-no-banner {
    background: url('../images/world.png') center center #e5ebF1 no-repeat scroll;
    background-size: cover;
}

.tournament-magnifier {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
}

.tournament-short-details-container {
    width: calc(50% - 3rem);
}

.bg-transluent {
    background-color: rgba(0, 0, 0, 0.2);
}

.breadcrumb {
    @apply px-4 mb-2 hidden lg:block;
}

.breadcrumb .separator {
    @apply mx-2;
}

.large-map-container {
    @apply w-full h-full;
}

.gmnoprint div,
.gm-style-cc div {
    opacity: 0.5 !important;
    background-color: transparent !important;
    color: #626262 !important;
}

.gm-bundled-control div {
    opacity: 1 !important;
}

.gm-fullscreen-control {
    @apply bg-theme-medium_dark hover:bg-theme-medium_dark_hover !important;
}

.gm-fullscreen-control > img {
    filter: brightness(150%);
}

#gmap img[alt='Google'] {
    opacity: 0.2 !important;
}

.letter-group {
    @apply w-full lg:w-1/3 flex-1 flex-auto m-3;
}

.gm-style-iw-c {
    @apply bg-theme-medium_dark_focus !important;
}

.gm-style .gm-style-iw-t::after {
    @apply bg-none bg-theme-medium_dark_focus !important;
    box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, .3) !important;
}

.gm-style .gm-style-iw-c button {
    @apply hidden !important;
}

.gm-control-active {
    @apply bg-theme-gray hover:bg-theme-virtual_back !important;
}

.gradient-background {
    background: linear-gradient(18deg, rgb(22, 39, 59) 0%, rgb(4, 21, 41) 6%, rgb(27, 44, 64) 44%, rgb(23, 40, 60) 100%);
}

.text-base img {
    @apply inline-block mx-0 my-2 lg:m-4;
}

.text-base p {
    @apply mb-3;
}

.text-base h2,
.text-base h3,
.text-base h4,
.text-base h5,
.text-base h6 {
    @apply inline-block w-full mb-3;
}

.footer-container a .menu-link-container {
    @apply relative;
}

.mtgdc-info .menu-link-container,
.moxfield .menu-link-container,
.mtgtop8 .menu-link-container {
    @apply pl-4;
}

.mtgdc-info .menu-link-container::before {
    @apply inline-block h-4 w-4 absolute;
    content: ' ';
    background: url('../images/mtgdc.png') transparent center center no-repeat scroll;
    background-size: cover;
    left: -0.3rem;
    top: 2px
}

.moxfield .menu-link-container::before {
    @apply inline-block h-4 w-4 absolute;
    content: ' ';
    background: url('../images/moxfield.png') transparent center center no-repeat scroll;
    background-size: cover;
    left: -0.4rem;
    top: 2px
}

.mtgtop8 .menu-link-container::before {
    @apply inline-block h-4 w-4 absolute;
    content: ' ';
    background: url('../images/mtgtop8.png') transparent center center no-repeat scroll;
    background-size: cover;
    left: -0.4rem;
    top: 2px
}

.flex-break {
    flex-basis: 100%;
    @apply h-0 lg:h-8;
}

.large-card-container {
    min-height: 800px;
    @apply flex flex-wrap justify-center p-2 lg:p-10;
}

.large-card-container .large-card-inner {
    @apply flex justify-center;
}

.large-card-inner img {
    @apply inline-block m-auto;
    max-height: 18rem;
}

.large-card-text {
    @apply text-3xl lg:text-4xl uppercase text-center text-theme-dark hover:text-theme-medium_dark_hover py-2;
    font-family: 'tekobold', sans-serif;
    text-shadow: -1px 0 1px #333;
}

.large-card-subtext {
    @apply text-xl lg:text-2xl uppercase text-center text-theme-medium_dark inline-block mt-1;
    font-family: 'tekolight', sans-serif;
}

.large-card-subtext,
.large-card-inner a {
    @apply z-50;
}

.large-card-subtext.infolink {
    @apply text-base lg:text-xl;
    text-shadow: -1px 0 1px #333;
}

.large-card-subtext.infolink a:hover {
    @apply text-theme-dark;
}

.large-card-text .focus {
    @apply text-theme-yellow;
}

.large-card-inner .large-card-image {
    @apply max-h-28;
}

/*noinspection CssOverwrittenProperties*/
.large-card-inner {
    @apply inline-block p-4 lg:px-10 lg:py-4 rounded flex flex-col justify-center box-border border-4 border-theme-dimmed_light hover:border-theme border-solid relative;
    background: transparent linear-gradient(120deg, rgba(255, 255, 255, 0.9) 0%, rgba(185, 190, 205, 0.6) 30%, rgba(180, 203, 228, 0.8) 60%, rgba(167, 190, 214, 0.7) 70%, rgba(255, 255, 255, 0.9) 100%);
    min-height: 180px;
    transition: all 0.15s ease-in;
    width: 45%;
    margin: 0 2% 5rem 2%;
    border-image-outset: 25px;
    border-image-repeat: round;
    border-image-slice: 33.33333%;
    border-image-source: url('../images/bordertile.png');
    border-image-width: 130px;
    border-width: 1px;
}

.large-card-inner.disabled {
    border-image-source: url('../images/bordertile-off.png');
}

.large-card-inner.disabled img {
    filter: grayscale(90%);
}

.large-card-inner:hover {
    background: rgba(255, 255, 255, 0.85) linear-gradient(120deg, rgba(255, 255, 255, 0.75) 0%, rgba(185, 190, 205, 0.4) 30%, rgba(180, 203, 228, 0.55) 60%, rgba(167, 190, 214, 0.4) 70%, rgba(255, 255, 255, 0.85) 100%);
    box-shadow: #fff 0 0 12px -1px;
}

.large-card-inner.disabled,
.large-card-inner.disabled:hover {
    @apply border-theme-medium_dark_hover hover:border-theme-medium_dark_hover;
    background: transparent linear-gradient(120deg, rgba(255, 255, 255, 0.75) 0%, rgba(180, 180, 180, 0.4) 30%, rgba(200, 200, 200, 0.55) 60%, rgba(160, 160, 160, 0.4) 70%, rgba(255, 255, 255, 0.85) 100%) !important;
    cursor: not-allowed;
}

.large-card-inner.disabled:hover {
    box-shadow: none;
}

.large-card-inner-background {
    @apply absolute z-0 m-auto opacity-50 block !important;
    max-height: 100% !important;
    max-width: 100% !important;
    left: -30% !important;
    margin-left: 50% !important;
}

@media only screen and (max-width: 1024px) {
    .large-card-inner {
        width: 90%;
        margin: 2rem 5%;
    }

    .large-card-inner-background {
        left: 0 !important;
        margin-left: 0 !important;
    }
}

.tournament-search-form-toggle {
    @apply w-full lg:w-1/2 py-2 inline-block text-theme-yellow hover:text-theme-badge text-center cursor-pointer text-xl;
}

.tournament-search-form-toggle img {
    @apply w-8 h-8 inline-block mx-4;
}

.tournament-search-form-toggle-container {
    @apply flex justify-center;
}

.tournament-search-form-toggle .up {
    @apply inline-block;
}

.tournament-search-form-toggle.toggle-collapsed .up {
    @apply hidden;
}

.tournament-search-form-toggle .down {
    @apply hidden;
}

.tournament-search-form-toggle.toggle-collapsed .down {
    @apply inline-block;
}

.tournament-search-form {
    @apply opacity-100 transition transition-all h-auto;
}

.form-collapsed {
    @apply opacity-0 h-0;
}

.sharer-bar {
    @apply leading-tight w-full inline-block h-6 text-center;
}

.share-link {
    @apply inline-block px-3 py-1 leading-normal h-6 m-auto;
}

.share-link span {
    @apply inline-block h-6 m-auto;
}

.share-link svg {
    @apply h-6 w-auto inline-block m-auto;
}

.text-red-700 {
    @apply bg-theme-flash_error_back text-theme-danger_focus font-bold !important;
}

.home-header-container {
    background: linear-gradient(70deg, rgba(22, 39, 59, 0.8) 0%, rgba(4, 21, 41, 0.2) 51%, rgba(27, 44, 64, 0.2) 84%, rgba(23, 40, 60, 0.8) 100%);
    box-shadow: 0 1px 9px -3px #fff;
    @apply py-3 lg:py-5 my-6 lg:my-12;
}

.home-split-container {
    @apply flex flex-wrap lg:flex-nowrap my-4 lg:my-8;
}

.home-split {
    @apply flex w-full lg:w-1/2 justify-center relative flex-col overflow-hidden;
}

.home-split-right::before {
    content: ' ';
    position: absolute;
    display: block;
    background: url('../images/circular.png') center center no-repeat scroll transparent;
    width: 100%;
    height: 100%;
    right: 3em;
    top: 0;
    transition: all 0.1s ease-in;
    animation-name: circle-rotate;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    opacity: 0.8;
}

@media only screen and (max-width: 1024px) {
    .home-split-right::before {
        right: 0;
    }
}

@keyframes circle-rotate {
    from {
        filter: brightness(1.0);
        transform: rotate(0deg);
    }
    50% {
        filter: brightness(0.8);
        transform: rotate(180deg);
    }
    to {
        filter: brightness(1.0);
        transform: rotate(360deg);
    }
}

.home-locate-me {
    @apply w-full lg:w-1/2;
}

.cke_dialog_contents {
    @apply text-theme;
}

.cke_dialog_body label {
    @apply text-theme;
}

.cke_dialog tr {
    @apply bg-theme-medium_dark hover:bg-theme-medium_dark !important;
}

@keyframes main-logo-pulse {
    from {
        filter: brightness(1.0);
    }
    15% {
        filter: brightness(1.6);
    }
    50% {
        filter: brightness(1.1);
    }
    70% {
        filter: brightness(1.05);
    }
    to {
        filter: brightness(1.0);
    }
}

@keyframes main-logo-bg-pulse {
    from {
        opacity: 0;
    }
    15% {
        opacity: 1.0;
    }
    50% {
        opacity: 0.3;
    }
    70% {
        opacity: 0.15;
    }
    to {
        opacity: 0;
    }
}

.main-logo-pulse {
    transition: all 0.1s ease-in;
    animation-name: main-logo-pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    @apply inline-block;
}

.home-about {
    @apply relative inline-block text-center px-4;
}

.home-about span {
    @apply relative inline-block w-full uppercase text-3xl text-center mt-3;
    font-family: 'tekoregular', sans-serif;
}

.home-about:hover .main-logo-pulse {
    @apply opacity-100 !important;
    animation: none;
    transform: scale(0.98);
}

.home-about:hover::before {
    @apply opacity-0 !important;
    animation: none;
}

/*noinspection CssOverwrittenProperties*/
.home-about::before {
    content: ' ';
    opacity: 0;
    transition: opacity 0.1s ease-in;
    animation-name: main-logo-bg-pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    position: absolute;
    margin: auto;
    display: block;
    box-shadow: 0 0 150px 55px #fff;
    content: '';
    width: 1px;
    height: 1px;
    top: 49%;
    left: 49%;
}

.transluent-rows-fieldset {
    background: linear-gradient(70deg, rgba(32, 49, 69, 0.9) 0%, rgba(14, 31, 51, 0.5) 51%, rgba(37, 54, 74, 0.6) 84%, rgba(33, 50, 70, 0.9) 100%);
}

.tournaments-quicklist-container {
    @apply w-full flex flex-row flex-wrap justify-center py-2 lg:py-6;
}

.tournament-quicklist-item {
    @apply relative box-border pb-4;
    margin: 2em 2%;
    width: 46%;
}

@media only screen and (max-width: 1800px) {
    .tournament-quicklist-item {
        width: 90%;
        margin: 2rem 5%;
    }
}

.tournament-quicklist-item .tournament-size-badge-container {
    top: -45px;
}

.tournament-quicklist-item .tournaments-info-right {
    @apply text-xs xl:text-sm xl:leading-tight;
}

.tournament-quicklist-item .table-picture-thumbnail-container a {
    @apply flex justify-center lg:justify-end;
}

.tournament-quicklist-item .table-picture-thumbnail-container img {
    max-width: 90%;
}

.tournament-quicklist-item .tournaments-info-left {
    background: linear-gradient(90deg, rgba(220, 220, 236, 0) 0%, rgba(220, 220, 236, 0.6) 10%);
}

@media only screen and (max-width: 1024px) {
    .tournament-quicklist-item .tournaments-info-left {
        background: none;
    }

    .tournament-list-item .tournaments-info-right {
        background: none;
        @apply mx-4 mt-3;
    }
}

.earth-surface {
    @apply pb-16 hidden lg:block;
    background: #000 url('../images/earth-surface.jpg') center top no-repeat scroll;
    background-size: cover;
    bottom: -30px;
    min-height: 550px;
}
